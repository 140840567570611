#logo {
  & a {
    display: block;
  }
}

.box_wrapper {
  display: block;

  & .row_1 {
    display: none;
  }

  & .row_2 {
    float: left;
    width: 25%;
  }

  & .image_container {
    margin-left: 0;
    margin-right: 0;

    & img {
      width: 90%;
      height: auto;
    }
  }
}
